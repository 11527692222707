@custom-media --page-max-width (max-width: 1140px);
@custom-media --tablet (max-width: 1024px);
@custom-media --mobile (max-width: 768px);
@custom-media --header-breakpoint (max-width: 768px);

@custom-media --wider-than-page-max-width (min-width: 1141px);
@custom-media --wider-than-tablet (min-width: 1025px);
@custom-media --wider-than-mobile (min-width: 769px);

:root {
  /* Sizes */
  --page-max-width: 1140px;

  --page-horizontal-padding: 24px;
  --page-left-padding: var(--page-horizontal-padding);
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: var(--page-horizontal-padding);
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );

  --header-height: 86px;

  --sidebar-width: 310px;
  --sidebar-gap: 108px;
  --text-content-max-width: 640px;

  /* Spacings */
  --spacing-72: 72px;
  --spacing-48: 48px;

  /* Font families */
  --font-family-title: 'Moneta Sans', sans-serif;
  --font-family-text: 'Work Sans', sans-serif;

  /* Font sizes */
  --font-size-40: 40px;
  --font-size-32: 32px;
  --font-size-24: 24px;
  --font-size-16: 16px;

  /* Z-indexes */
  --z-index-content: 10;
  --z-index-header: 20;
  --z-index-mobile-menu: 30;
  --z-index-mobile-menu-button: 40;
  --z-index-dropdown: 50;

  /* Colors */
  --white: #ffffff;
  --white-rgb: 255, 255, 255;

  --black: #000000;
  --black-rgb: 0, 0, 0;

  --grey: #f5f5f4;
  --grey-rgb: 245, 245, 244;
}
@media (--tablet) {
  :root {
    --sidebar-gap: 32px;
  }
}
@media (--mobile) {
  :root {
    /* Spacings */
    --spacing-72: 48px;
    --spacing-48: 32px;

    /* Font sizes */
    --font-size-40: 32px;
    --font-size-32: 24px;
    --font-size-24: 16px;
    --font-size-16: 14px;
  }
}
