.hero {
  --scroll: 0;

  --min-height: 650px;
  --transition-duration: 0ms;
  --illustration-scale-end-factor: 3;

  width: 100%;
  height: 180lvh;
  min-height: var(--min-height);

  user-select: none;
  transform: translate3d(0, 0, 0);
}
.hero--mounted {
  --transition-duration: 150ms;
}
@media (max-width: 2000px) {
  .hero {
    --illustration-scale-end-factor: 2;
  }
}
@media (max-width: 1500px) {
  .hero {
    --illustration-scale-end-factor: 1.8;
  }
}
@media (--mobile) {
  .hero {
    --illustration-scale-end-factor: 3;

    height: 180lvh;
  }
}
@media (pointer: coarse) {
  .hero {
    --transition-duration: 0ms;
  }
}

.hero__inner {
  position: sticky;
  top: 0;

  height: 100lvh;
  min-height: var(--min-height);

  background: linear-gradient(
      0deg,
      rgba(196, 208, 255, 0.2) 0%,
      rgba(196, 208, 255, 0) 25%
    ),
    radial-gradient(
      72% 71% at 92% 10%,
      rgba(255, 212, 240, 0.64) 0%,
      rgba(255, 212, 240, 0) 100%
    ),
    radial-gradient(
      47% 76% at 40% 14%,
      rgba(255, 143, 216, 0.12) 0%,
      rgba(255, 143, 216, 0) 100%
    ),
    radial-gradient(
      63% 73% at 0% 31%,
      rgba(92, 89, 243, 0.14) 0%,
      rgba(92, 89, 243, 0) 100%
    ),
    radial-gradient(
      86% 98% at 17% -29%,
      rgba(140, 153, 250, 0.12) 7%,
      rgba(140, 153, 250, 0) 100%
    ),
    white;
}

.hero__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
}

.hero__phone {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 450px;

  transition: transform var(--transition-duration);
  backface-visibility: hidden;
  transform: translateX(-50%)
    translateY(calc(100% * (-0.25 - 0.3 * var(--scroll))))
    scale(calc(1 + (1 - var(--scroll))));
  transform-origin: center center;
}
@media (--mobile) {
  .hero__phone {
    width: 350px;
    max-width: calc(100lvw - 40px);

    transition: none;
    will-change: transform;
    transform: translateX(-50%)
      translateY(calc(100% * (-0.5 + 0.1 * var(--scroll))))
      scale(calc(1 + (1 - var(--scroll)) * 0.2));
  }
}

.hero__phone__illustration {
  --border-radius: 55px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  overflow: hidden;

  transition: transform var(--transition-duration);
  backface-visibility: hidden;
  transform: scale(
    calc((1 - var(--scroll)) * var(--illustration-scale-end-factor) + 1)
  );
  transform-origin: center 20%;
  pointer-events: none;
  border-radius: var(--border-radius);
  box-shadow: 0px 60px 102px 0px rgba(201, 209, 231, 0.2);
}
@media (--mobile) {
  .hero__phone__illustration {
    transition: none;
    will-change: transform;
    border-radius: 44px;
  }
}

.hero__phone__illustration::before {
  position: absolute;
  top: 1%;
  right: 3%;
  bottom: 51%;
  left: 3%;

  content: '';

  background-color: #dce1fe;
  border-radius: var(--border-radius);
}

.hero__phone__illustration img {
  position: relative;

  width: 100%;
  height: auto;
}
.hero__phone__illustration img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
}
