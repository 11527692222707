@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 400;
  font-style: normal;
  src: url('/font-faces/FilsonProAlan-Regular.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 400;
  font-style: italic;
  src: url('/font-faces/FilsonProAlan-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 500;
  font-style: normal;
  src: url('/font-faces/FilsonProAlan-Medium.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 500;
  font-style: italic;
  src: url('/font-faces/FilsonProAlan-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 700;
  font-style: normal;
  src: url('/font-faces/FilsonProAlan-Bold.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 700;
  font-style: italic;
  src: url('/font-faces/FilsonProAlan-BoldItalic.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'FilsonProAlan';
  font-weight: 900;
  font-style: normal;
  src: url('/font-faces/FilsonProAlan-Black.woff2') format('woff2');
}
