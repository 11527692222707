@keyframes floating-1 {
  from {
    transform: translate(0, 0px) scale(1);
  }
  65% {
    transform: translate(4px, -24px) scale(1.2);
  }
  to {
    transform: translate(0, 0px) scale(1);
  }
}
@keyframes floating-2 {
  from {
    transform: translate(0, 0px) scale(1);
  }
  65% {
    transform: translate(-8px, 16px) scale(1.1);
  }
  to {
    transform: translate(0, 0px) scale(1);
  }
}

.hero__orb {
  --size: 128px;
  --x-origin: 0;
  --y-origin: 0;
  --animation-name: floating-1;
  --animation-duration: 4000ms;

  position: absolute;

  object-fit: contain;

  will-change: transform;
  transition: transform var(--transition-duration);
  transform: translate(-50%, -50%)
    translateX(calc(100lvw * (var(--x-origin) * (1 - var(--scroll)))))
    translateY(calc(100lvh * (var(--y-origin) * (1 - var(--scroll)))));
  user-select: none;
}
@media (--mobile) {
  .hero__orb {
    transition: none;
  }
}

.hero__orb img {
  width: var(--size);
  height: var(--size);

  animation-name: var(--animation-name);
  animation-duration: var(--animation-duration);
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* Shell */
.hero__orb--1 {
  --size: 136px;
  --x-origin: -0.25;
  --y-origin: -0.1;
  --animation-name: floating-1;
  --animation-duration: 8000ms;

  top: 25%;
  left: 20%;
}
@media (--mobile) {
  .hero__orb--1 {
    --size: 136px;
    --x-origin: -0.25;
    --y-origin: -0.3;

    top: 25%;
    left: 10%;
  }
}
/* Hearth */
.hero__orb--2 {
  --size: 92px;
  --x-origin: 0.25;
  --y-origin: -0.1;
  --animation-name: floating-2;
  --animation-duration: 6000ms;

  top: 20%;
  left: 80%;
}
@media (--mobile) {
  .hero__orb--2 {
    --size: 92px;
    --x-origin: 0.25;
    --y-origin: -0.1;

    top: 20%;
    left: 75%;
  }
}
/* Flower */
.hero__orb--3 {
  --size: 110px;
  --x-origin: 0.2;
  --y-origin: 0.3;
  --animation-name: floating-2;
  --animation-duration: 10000ms;

  top: 55%;
  left: 85%;
}
@media (--mobile) {
  .hero__orb--3 {
    display: none;
  }
}
/* Peach */
.hero__orb--4 {
  --size: 136px;
  --x-origin: -0.35;
  --y-origin: 0.2;
  --animation-name: floating-1;
  --animation-duration: 10000ms;

  top: 80%;
  left: 30%;
}
@media (--mobile) {
  .hero__orb--4 {
    display: none;
  }
}
