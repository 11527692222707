.hero__carousel {
  --height: 124px;

  position: relative;

  height: var(--height);
}
* + .hero__carousel {
  margin-top: 30px;
}
@media (--mobile) {
  .hero__carousel {
    --height: 96px;
  }
  * + .hero__carousel {
    margin-top: 16px;
  }
}

.hero__carousel__wrap {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  gap: 6px;
  height: var(--height);

  transition: transform var(--transition-duration);
  transform: translate(-50%, -50%)
    translateY(calc(100% * (0.25 * (1 - var(--scroll)))))
    scale(calc(1 - (1 - var(--scroll)) * 0.2));
}
@media (--mobile) {
  .hero__carousel__wrap {
    transition: none;
    will-change: opacity, transform;
    opacity: calc(1 - (1 - var(--scroll)) * 1);
    transform: translate(-50%, -50%)
      translateY(calc(100% * (1 * (1 - var(--scroll)))))
      scale(calc(1 + (1 - var(--scroll)) * 0.3));
  }
}

.hero__carousel__wrap img {
  width: auto;
  height: 100%;
  object-fit: contain;
}
