.skeleton {
  --vertical-padding: 60px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  font-family: 'FilsonProAlan', sans-serif;
}
@media (--mobile) {
  .skeleton {
    --vertical-padding: 40px;
  }
}

.skeleton__title {
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}
@media (--tablet) {
  .skeleton__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .skeleton__title {
    font-size: 24px;
  }
}

.skeleton__description {
  max-width: 90ch;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
* + .skeleton__description {
  margin-top: 12px;
}

.skeleton__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
}
@media (--tablet) {
  .skeleton__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .skeleton__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
* + .skeleton__grid {
  margin-top: 40px;
}

.skeleton__grid img {
  width: 100%;
  height: auto;
  pointer-events: none;
}
