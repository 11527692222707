.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 1;

  height: 112px;
}
@media (--mobile) {
  .header {
    height: 80px;
  }
}

.header .max-width {
  display: flex;
  align-items: center;
  height: 100%;
}

.header__logo {
  display: flex;
  height: auto;
  align-items: center;
}

.header__logo img {
  width: height;
  height: 28px;
}
@media (--mobile) {
  .header__logo img {
    height: 20px;
  }
}
