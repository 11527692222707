.hero__phone__content {
  position: relative;

  padding: 96px 44px;

  font-family: 'FilsonProAlan', sans-serif;
}
@media (--mobile) {
  .hero__phone__content {
    padding: 60px 36px;
  }
}

.hero__tag {
  display: inline-block;
  padding: 8px 12px;

  color: #2515ba;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.24px;
  user-select: auto;

  background-color: #eff3ff;
  border-radius: 9999px;
}

.hero__title {
  color: #0e056b;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  user-select: auto;
}
* + .hero__title {
  margin-top: 14px;
}
@media (--mobile) {
  .hero__title {
    font-size: 20px;
  }
  * + .hero__title {
    margin-top: 10px;
  }
}

.hero__description {
  max-width: 33.3ch;

  color: #464754;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  user-select: auto;
}
* + .hero__description {
  margin-top: 14px;
}
@media (--mobile) {
  .hero__description {
    font-size: 14px;
  }
}
